/* General Styles */

/* Existing Styles */
/* ... (include the previous CSS styles here) ... */

/* Card Container for Past Rounds */

/* Container class for the prize pool section */
.prize-pool {
  text-align: center; /* Center aligning all content within this container */
  padding: 20px; /* Adding some padding around the container */
  background-color: #f9f9f9; /* Light background color for the entire container */
  border-radius: 15px; /* Rounded corners for the container */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow for depth */
  margin: 20px auto; /* Centering the container with some margin */
  max-width: 500px; /* Maximum width for the container */
  animation: fadeIn 1s ease-in-out; /* Fade-in animation when the component loads */

}

.presale-ended {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #34495e;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

}

.presale-ended h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
}

.presale-ended p {
  font-size: 1rem;
  color: white;
  margin-bottom: 20px;
}




/* Styling for the header within the prize pool container */
.prize-pool h1 {
  font-size: 2.2em;
  color: #324a5f;
  margin-bottom: 0.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Adding a subtle shadow to the text */

}

/* Styling for the paragraph within the prize pool container */
.prize-pool p {
  font-size: 2em;
  color: #4caf50;
  font-weight: bold;
  margin-top: 0;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for hover effects */
  animation: pulse 2s infinite ease-in-out; /* Adding a pulsing animation */

}

.prize-pool p:hover {
  transform: scale(1.05); /* Slightly scale up the paragraph on hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover for a "lifting" effect */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes pulse {
  0% {
    transform: scale(1); /* Original size */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.1); /* Slightly larger size */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1); /* Back to original size */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background: linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),
    url('../assets/hero.png') no-repeat center center;
  background-size: cover;    
  background-size: cover; /* Cover the entire element */
}

.card-container1 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background: linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),
    url('../assets/hero.png') no-repeat center center;
  background-size: cover;    
  background-size: cover; /* Cover the entire element */
  height: 900px;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

/* Card Header */
.card h2 {
  color: #333;
  margin-top: 0;
}

/* Winning Numbers and Winners Style */
.winning-numbers-container,
ul {
  list-style: none;
  padding: 0;
}

.winning-number, .circle {
  display: inline-block;
  margin-right: 5px;
  /* Other existing styles for .circle */
}
.winning-number {

display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 35px;
  background-color: #5ddd7d;
  text-align: center;
  margin-right: 5px;
}

/* Responsive Design for Cards */
@media (max-width: 768px) {
  .card-container {
    grid-template-columns: 1fr;
  }
  .card-container1 {
    grid-template-columns: 1fr;
  }
}

/* Additional Styling for Card Details */
.card div {
  margin-bottom: 15px;
}

.card h3 {
  margin-bottom: 10px;
  color: #4caf50;
}
.card ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.card ul li {
  background-color: #333; /* Darker background for the list items */
  color: #fff; /* White text for contrast */
  padding: 5px 10px; /* Padding inside the list items */
  margin-bottom: 5px; /* Margin below each list item */
  border-radius: 5px; /* Rounded corners for the list items */
  box-sizing: border-box; /* Ensures padding does not affect the total width */
  word-break: break-all; /* Ensure long words do not overflow */
}

.card ul li:hover {
  background-color: #444; /* Slightly lighter background on hover */
}
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7f6;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Shimmer Effect */
.shimmer-effect {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #f4f7f6 0%, #e6ecef 50%, #f4f7f6 100%);
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

/* Top Bar */
.top-bar {
  background-color: #1a202c;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.app-name-container h1,
.wallet-info span,
.connect-wallet-button {
  margin: 0;
  padding: 0;
}

.app-name{
  color: white;
}

.wallet-info {
  background-color: #f4f4f7; /* Light grey background */
  border: 1px solid #d1d1d6; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 15px; /* Padding inside the container */
  max-width: 300px; /* Maximum width */
  margin: 10px auto; /* Centering the box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  text-align: center; /* Centering the text */
}

.wallet-address-display, .wallet-balance-display {
  display: block; /* Each element takes its own line */
  color: #333; /* Dark text color for readability */
  font-size: 0.9em; /* Slightly smaller font size */
  margin-bottom: 5px; /* Spacing between elements */
  word-break: break-all; /* Ensures the address doesn't overflow */
}

.wallet-address-display {
  font-weight: bold; /* Makes wallet address bold */
  background-color: #eaf0f6; /* Slightly different background for emphasis */
  border-radius: 5px; /* Rounded corners */
  padding: 5px 10px; /* Padding inside the element */
  margin-bottom: 10px; /* More spacing from the balance display */
}

.wallet-balance-display {
  color: #1a73e8; /* A different color to make balance stand out */
  font-weight: 600; /* Slightly bolder than normal text */
}


.connect-wallet-button {
background: #4c8bf5; /* Blue background for the buttons */
    border: none;
    border-radius: 20px; /* Rounded borders for the buttons */
    color: white;
    font-size: 1em;
    padding: 15px 30px; /* Padding inside the buttons */
    text-transform: uppercase;
    letter-spacing: 1px; /* Spacing between letters for a more defined look */
    width: 100%; /* Buttons take full width of the .action-buttons div */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button background color */
    
  }

/* Wallet Info */
.wallet-info span {
  display: block;
}

/* Buttons */
button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

/* Grid Container */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  padding: 20px;
}

.left-side,
.middle {
  padding: 20px;
}

.middle {
  text-align: center;
}

.app-logo {
  max-width: 100px;
  height: auto;
}

/* Ticket Selection */
.card1 {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.selected-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #ddd;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.selected-number.selected {
  background-color: #4caf50;
  color: white;
}

/* Ticket Table */
.card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.tickets-table h2 {
  margin-top: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px;
  text-align: left;
}

.circle {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 35px;
  background-color: #5ddd7d;
  text-align: center;
  margin-right: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .top-bar {
    flex-direction: column;
    text-align: center;
    display: block;
  }
}

.error-message {
  color: red; /* Red color for error text */
  background-color: #ffdddd; /* Light red background */
  border: 1px solid red; /* Red border */
  padding: 10px; /* Padding for spacing */
  margin: 10px 0; /* Margin for spacing from other elements */
  border-radius: 5px; /* Rounded corners */
  font-size: 1em; /* Font size */
  text-align: center; /* Centered text */
}
