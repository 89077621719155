/* General Header Styling */
.main-header {
  background-color: #000000; /* A deep gray for a sophisticated look */
  padding: 16px 0;
  color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.header-section.left img {
  height: 50px; /* Adjust based on actual logo size */
  transition: transform 0.2s ease-in-out;
}

.header-section.left img:hover {
  transform: scale(1.05); /* Gentle scaling effect on hover */
}

.nav-links {
  display: flex;
  align-items: center;
  list-style: none; /* Removes default list styling */
  padding-left: 0; /* Aligns links properly */
  margin: 0; /* Removes default margins */
  transition: all 0.3s ease-in-out; /* Smooth transition for visibility changes */
}

.nav-links a {
  color: #000000; /* Light grey color for better readability */
  text-decoration: none; /* Removes underline */
  padding: 12px 18px; /* Adequate padding for clickable area */
  font-size: 18px; /* Makes text larger and more legible */
  font-weight: 500; /* Medium font weight for better visibility */
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out; /* Smooth color transition */
  border-radius: 5px; /* Adds rounded corners for a modern look */
}





.nav-links.open {
  display: flex; /* Ensures links are visible when menu is open */
}


/* Right Section */
.header-section.right {
  padding: 10px 0;
}

/* Hamburger Menu - for smaller screens */
.hamburger-menu {
  display: none; /* Hidden by default, shown in media query */
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu span {
  display: block;
  height: 2px;
  width: 25px;
  background: #ffffff;
  margin: 4px 0;
  transition: background 0.25s ease-in-out;
}

.hamburger-menu:hover span {
  background: #7289DA; /* Bright color on hover */
}

/* Mobile Menu */
.mobile-menu {
  display: none; /* Initially hidden, controlled by 'open' class */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(35, 39, 42, 0.95);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.mobile-menu.open {
  display: flex;
}

.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .hamburger-menu {
      display: flex;
  }

  .nav-links {
      display: none;
  }
}

/* General button styling */
.button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background: #00ffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase; /* Optional: adds a stylistic choice */
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

/* Connect button specifics, can customize further if ConnectButton has unique aspects */
.connect-button {
  background: linear-gradient(to right, #11998e, #38ef7d); /* Vivid green gradient */
  color: #0c0c0c; /* Dark text color for contrast */
}

.connect-button:hover {
  background: linear-gradient(to right, #38ef7d, #11998e);
}

/* Mobile Menu Connect button adaptation */
.mobile-menu .connect-button {
  width: 80%; /* Adjusts width to better fit mobile screens */
  margin-top: 20px; /* Adds space above the button */
}

/* Additional hover effect for all buttons in mobile menu */
.mobile-menu .button:hover {
  opacity: 0.85;
  box-shadow: 0 8px 16px rgba(0, 255, 0, 0.2); /* Bright colored shadow for flair */
}
