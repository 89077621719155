/* Base Styles */
.connect-wallet-center {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.network-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

body {
  background-color: #2c3e50; /* Dark Blue Background */
  color: #ecf0f1; /* Light Text Color */
  padding: 0px;
  transition: background-color 0.3s ease;
}

/* Card Style */
/* Center Alignment for Card */
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card Style */
.card {
  background-color: #34495e; /* Medium Dark Blue */
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  /* Size */
  width: 500px; /* Fixed width */
  min-height: 200px; /* Minimum height */
}

.card:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: #ecf0f1; /* Light Text Color */
  transition: color 0.3s ease;
}

/* Remaining Time Section */
.remaining-time {
  color: #ecf0f1;
}

.time-label {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
  transition: font-size 0.3s ease;
}

.timer {
  display: flex;
  justify-content: space-around;
  transition: opacity 0.3s ease;
}

.timer:hover {
  opacity: 0.8;
}

.info {
  display: flex;
  justify-content: space-around;
  transition: opacity 0.3s ease;
  flex-direction: column;
}
.info:hover {
  opacity: 0.8;
}

.time-unit {
  text-align: center;
  transition: transform 0.3s ease;
}

.time-unit:hover {
  transform: scale(1.05);
}

.number {
  font-size: 28px;
  color: #e74c3c; /* Bright Red */
  font-weight: 700;
  display: block; /* Makes the number take up the full width */
  transition: font-size 0.3s ease, color 0.3s ease;
}

.number:hover {
  font-size: 32px;
  color: #c0392b; /* Dark Red */
}

.unit {
  font-size: 16px;
  transition: font-size 0.3s ease;
}

/* Price Container */
.price-container {
  /* Inherits .card styles */
}

.price-label {
  font-size: 18px;
  color: #bdc3c7; /* Light Grey */
  margin-bottom: 10px;
  font-weight: 500;
}

.price-box {
  font-size: 16px;
  color: #ecf0f1; /* Light Text Color */
  padding: 10px;
  border: 1px solid #7f8c8d; /* Medium Grey */
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.price-box:hover {
  background-color: #95a5a6; /* Light Grey */
  border-color: #3498db; /* Bright Blue */
}

/* Sell Bar */
.sell-bar {
  background-color: #7f8c8d; /* Medium Grey */
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.sell-bar:hover {
  background-color: #95a5a6; /* Light Grey */
}

.sell-progress {
  height: 20px;
  background-color: #2ecc71; /* Bright Green */
  text-align: center;
  color: white;
  line-height: 20px;
  border-radius: 5px;
  transition: width 0.5s ease;
}

.total-sold-price {
  margin-top: 15px;
  font-size: 50px;
  color: #ecf0f1;
  font-weight: 500;
  text-align: center;
  animation: bounce 2s ease infinite; /* Adjust the duration and animation properties as needed */
}

.total-sold-price-label {
  margin-top: 15px;
  font-size: 30px;
  color: #ecf0f1;
  font-weight: 500;
  transition: color 0.3s ease;
  text-align: center;
}

/* Purchase Form */
.purchase-form {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  transition: transform 0.3s ease;
}

.purchase-form:hover {
  transform: translateY(-2px);
}

.purchase-form input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #7f8c8d; /* Medium Grey */
  background-color: #34495e; /* Medium Dark Blue */
  color: #ecf0f1; /* Light Text Color */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.purchase-form input:focus {
  border-color: #3498db; /* Bright Blue */
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.purchase-form button {
  background-color: #e74c3c; /* Bright Red */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.purchase-form button:hover {
  background-color: #c0392b; /* Dark Red */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Style for the first <div> containing purchased tokens */
/* You can adjust these styles as needed */
.div-purchased-tokens {
  margin-top: 20px;
  padding: 10px;
  background-color: #95a5a6; /* Light Grey */
  border-radius: 8px;
  text-align: center;
}

.div-purchased-tokens p {
  font-size: 18px;
  color: #ecf0f1;
}

/* Style for the second <div> containing the Claim Tokens button */
/* You can adjust these styles as needed */
.div-claim-button {
  margin-top: 20px;
  text-align: center;
}

/* Style for the Claim Tokens button */
/* These styles match your existing .claim-button styles */
.claim-button {
  background-color: #27ae60; /* Bright Green */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.claim-button[disabled] {
  background-color: gray;
  cursor: not-allowed;
}

/* Style for the third <div> containing the Claim Start Date */
/* You can adjust these styles as needed */
.claim-start-date {
  margin-top: 20px;
  font-size: 16px;
  color: #ecf0f1;
  text-align: center;
}
