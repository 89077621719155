/* General Styles */
.connect-wallet-button, .dropbtn {
    background-color: #00ffff; /* Green background */
    color: rgb(0, 0, 0);
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    margin: 10px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.connect-wallet-button:hover, .dropbtn:hover {
    background-color: #9b4040; /* Darker green on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

/* Dropdown Styles */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    background-color: #fff;
    min-width: 220px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 8px;
    padding: 12px;
    margin-top: 5px;
    position: fixed; /* Position fixed to the viewport */
  top: 75px; /* Adjust these values as needed */
  right: 50px; /* Adjust these values as needed */
  width: 300px; /* Set a specific width */
}

.dropdown-content p {
    color: #333;
    padding: 8px 12px;
    margin: 4px 0;
    transition: background-color 0.2s ease;
    
}

.dropdown-content p:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* Wallet Balance Display */
.wallet-balance-display {
    font-size: 14px;
    color: #333;
    margin-top: 8px;
    display: block; /* Ensures the balance is on a new line */
}

/* Adjustments for smaller screens */
@media screen and (max-width: 600px) {
    .connect-wallet-button, .dropbtn {
        width: 100%;
        margin-top: 0;
    }
}

